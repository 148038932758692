import ApplicationController from './application_controller'
import { uploadedFileData, configureUploadServer } from '../utils/uppy'
import { Core, DropTarget, StatusBar } from 'uppy'
import { post } from '@rails/request.js'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import Cookies from 'js-cookie'
import { getUppyLocale } from '../utils/uppy'

export default class extends ApplicationController {
  static targets = ['dropzone', 'fileInput']
  static values = {
    accept: String,
    uploadServer: String,
    postUploadUrl: String,
    statusBarSelector: String,
    addToFolderId: String,
  }

  dropzoneTargetConnected() {
    this.initUppy()
  }

  initUppy() {
    const locale = Cookies.get('locale') || 'en'
    this.uppy = new Core({
      id: 'uppy',
      autoProceed: true,
      locale: getUppyLocale(locale),
      restrictions: {
        allowedFileTypes: this.acceptValue.split(','),
        minNumberOfFiles: 1,
        maxNumberOfFiles: null,
      },
    })

    // Target MUST be a string selector to avoid obscure
    // differences between document and iframe document Element implementations
    this.uppy.use(DropTarget, { target: '[data-uppy-target="dropzone"]' })
    this.uppy.use(StatusBar, {
      target: this.statusBarSelectorValue,
      hideRetryButton: true,
      hideUploadButton: true,
      hideAfterFinish: false,
      showProgressDetails: true,
    })
    this.uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 350,
      waitForThumbnailsBeforeUpload: true,
    })

    configureUploadServer(this.uploadServerValue, this.uppy)

    this.uppy.on('upload', () => this.dispatch('upload-start'))

    this.uppy.on('upload-success', this.onUploadSuccess.bind(this))
    this.uppy.on('complete', () => this.uppy.reset())

    if (this.hasFileInputTarget) {
      this.uppy.on('file-removed', () => {
        this.fileInputTarget.value = null
      })
      this.uppy.on('complete', () => {
        this.fileInputTarget.value = null
      })
    }
  }

  onUploadSuccess(file, response) {
    const fileData = uploadedFileData(this.uploadServerValue, file, response)
    let thumbnailPreviewUrl = URL.createObjectURL(file.data)

    this.dispatch('upload-success', { detail: { fileData } })
    this.createResourceFromUploadData(fileData, thumbnailPreviewUrl)
  }

  async createResourceFromUploadData(fileData, thumbnailPreviewUrl) {
    const response = await post(this.postUploadUrlValue, {
      body: JSON.stringify({
        image: {
          file: fileData,
          tag_ids: [this.addToFolderIdValue],
        },
        thumbnail_preview_url: thumbnailPreviewUrl,
      }),
      responseKind: 'turbo-stream',
    })

    if (!response.ok) {
      console.log('File upload failed')
    }
  }

  handleFileFieldChange(event) {
    ;[...event.currentTarget.files].forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    })
  }
}
